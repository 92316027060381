import Header from '@/components/Header'
import Heading from '@/components/Heading/Heading'
import Meta from '@/hoc/Meta'
import { BaseComponentProps } from '@/types'
import { graphql, Link, Script } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FC } from 'react'
import { IoIosArrowDropleftCircle } from 'react-icons/io'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeSlug from 'rehype-slug'
import remarkToc from 'remark-toc'

import bgElement3 from '@/images/bg-elements-3.svg'
import bgElement4 from '@/images/bg-elements-4.svg'
import SVG from '@/components/SVG'
import ArticleBox from '@/components/ArticleBox'
import LoreBox from '@/components/LoreBox'

type Article = {
	__typename: string
	id: string
	slug: string
	title: string
	content: string
	publishedAt: string
	hideCoverImage?: boolean
	coverImage: {
		url: string
		gatsbyImageData: any
		alt: string
	}
	category: {
		title: string
		slug: string
	}
	seo: {
		title: string
		twitterCard: string
		description: string
		image: {
			url: string
		}
	}
}

interface IArticleTemplateProps extends BaseComponentProps {
	data: {
		news?: Article
		lore?: Article
		relatedNews?: {
			nodes: Article[]
		}
		relatedLore?: {
			nodes: Article[]
		}
	}
	pageContext: any
	location: any
}

const ArticleTemplate: FC<IArticleTemplateProps> = ({
	pageContext,
	data,
	location,
}) => {
	const pageData = data?.news || data?.lore
	const relatedArticles = data?.relatedNews?.nodes || data?.relatedLore?.nodes
	const isNews = pageData?.__typename === `DatoCmsNews`

	if (!pageData || !relatedArticles) return null

	return (
		<div className="min-h-screen h-screen overflow-y-scroll w-screen relative bg-light2">
			{
				// <!-- Segment Pixel - Vela Games - Relevant Pages - DO NOT MODIFY -->
			}
			<img
				src="https://secure.adnxs.com/seg?add=34274190&t=2"
				width="1"
				height="1"
				style={{ zIndex: -1, position: `absolute`, top: 0, left: 0 }}
			/>
			{
				// <!-- End of Segment Pixel -->
			}
			<Meta {...{ seo: pageData?.seo }} />
			<Script>
				{`(function(G,m,s,g,h,t){G.gsght=G.gsght||function(){
				(G.gsght.q=G.gsght.q||[]).push(arguments)};G.gsght.q=[],h=m.createElement(s),
				t=m.getElementsByTagName(s)[0];h.async=1;h.src=g;t.parentNode.insertBefore(h,t)
				})(window,document,'script','https://cdn.gsght.com/sdk.js');

				gsght('init', 'GS-101278');
				gsght('set', 'ga_enabled', true)
				gsght('send')`}
			</Script>
			<Header locale={pageContext.locale} pathname={location.pathname} />
			<main className="pt-20 lg:pt-24 flex flex-col max-w-7xl w-full mx-auto gap-8 z-30">
				<div className="absolute w-full h-full top-0 left-0 z-10">
					<div className="relative w-full h-full">
						<div className="absolute right-0 top-0 teal-triangle-bg">
							<SVG
								src={bgElement4}
								className="hidden lg:block bg-black3 h-2 w-4 absolute top-40 clipped-parallelogram-alt left-[5.1rem] delayed-fade-in-opacity"
							/>
							<SVG
								src={bgElement4}
								className="hidden lg:block bg-black3 h-2 w-4 absolute top-80 clipped-parallelogram-alt left-[10.8rem] delayed-fade-in-opacity"
							/>
						</div>
					</div>
					<div className="h-full w-full absolute top-0 left-0 delayed-fade-in-opacity">
						<SVG
							src={bgElement3}
							className="absolute right-20 top-10 w-5 fill-light2"
						/>
						<SVG
							src={bgElement3}
							className="absolute right-56 top-60 w-5 fill-light2"
						/>
					</div>
				</div>
				<div className="grid grid-cols-1 lg:grid-cols-5 gap-8 w-full items-baseline z-30">
					<article className="flex mx-auto prose prose-h2:!text-5xl prose-h4:text-2xl prose-h5:font-semibold lg:prose-xl prose-li:!my-0 flex-col gap-2 lg:gap-8 col-span-3 px-2">
						<div>
							<div className="flex flex-row items-center gap-2 group z-30 lg:-mt-8 mb-4">
								<IoIosArrowDropleftCircle className="inline-block text-xl text-black5 group-hover:text-teal transition-colors" />
								<Link
									to={`/${pageData.__typename === `DatoCmsNews` ? `news` : `lore`}`}
									className="inline-block text-xl uppercase no-underline font-bold text-black5 group-hover:text-teal transition-colors"
								>
									Return to{` `}
									{pageData.__typename === `DatoCmsNews` ? `News` : `Lore & Storyline`}
								</Link>
							</div>
							<div className="flex flex-row gap-4">
								<p className="text-2xl uppercase font-semibold text-teal no-underline flex items-center !my-0">
									{pageData.category.title}
								</p>
								{isNews && (
									<>
										<p className="!my-0">-</p>
										<p className="text-2xl uppercase font-semibold text-teal !my-0">
											{new Intl.DateTimeFormat(`en-US`, {
												year: `numeric`,
												month: `long`,
												day: `2-digit`,
											}).format(new Date(pageData.publishedAt))}
										</p>
									</>
								)}
							</div>
							<Heading type={1} size="6xl" className="!mb-0">
								{pageData.title}
							</Heading>
						</div>
						{!pageData.hideCoverImage && (
							<GatsbyImage
								image={pageData.coverImage.gatsbyImageData}
								alt={pageData.title}
							/>
						)}
						<div>
							<ReactMarkdown
								remarkPlugins={[
									remarkGfm,
									[
										remarkToc,
										{ heading: `Table of Contents`, tight: true, maxDepth: 3 },
									],
								]}
								rehypePlugins={[rehypeSlug]}
							>
								{pageData.content}
							</ReactMarkdown>
						</div>
					</article>
					<aside className="px-2 lg:px-8 sticky lg:top-20 self-start col-span-2 flex flex-col pb-12 w-screen lg:w-full">
						{relatedArticles.length > 0 && (
							<div className="lg:ml-auto mx-auto">
								<Heading type={5} className="!text-xl">
									Related Articles
								</Heading>
								<div className="flex flex-col gap-2 lg:gap-4">
									{relatedArticles.map((article: Article) =>
										article.__typename === `DatoCmsNews` ? (
											<ArticleBox article={article} />
										) : (
											<LoreBox small article={article} />
										)
									)}
								</div>
							</div>
						)}
					</aside>
				</div>
			</main>
		</div>
	)
}

export const query = graphql`
	query getNews(
		$id: String
		$category: String
		$isNews: Boolean!
		$isLore: Boolean!
	) {
		news: datoCmsNews(id: { eq: $id }) @include(if: $isNews) {
			__typename
			id
			slug
			title
			content
			coverImage {
				url
				gatsbyImageData(placeholder: BLURRED)
			}
			category {
				title
				slug
			}
			publishedAt
			seo {
				title
				twitterCard
				description
				image {
					url
				}
			}
			hideCoverImage
		}
		relatedNews: allDatoCmsNews(
			filter: { category: { slug: { eq: $category } }, id: { ne: $id } }
			limit: 3
		) @include(if: $isNews) {
			nodes {
				__typename
				id
				slug
				title
				coverImage {
					url
					gatsbyImageData(placeholder: BLURRED)
				}
				category {
					title
					slug
				}
				publishedAt
			}
		}
		lore: datoCmsLore(id: { eq: $id }) @include(if: $isLore) {
			__typename
			id
			slug
			title
			content
			hideCoverImage
			coverImage {
				url
				gatsbyImageData(placeholder: BLURRED)
			}
			category {
				title
				slug
			}
			publishedAt
			seo {
				title
				twitterCard
				description
				image {
					url
				}
			}
		}
		relatedLore: allDatoCmsLore(
			filter: { category: { slug: { eq: $category } }, id: { ne: $id } }
			limit: 3
		) @include(if: $isLore) {
			nodes {
				__typename
				id
				slug
				title
				coverImage {
					url
					gatsbyImageData(placeholder: BLURRED)
				}
				category {
					title
					slug
				}
				publishedAt
			}
		}
	}
`

export default ArticleTemplate
